import React from "react";
import { Button, Heading, Icon, Link, SlideFade, Flex } from "@chakra-ui/react";
import { VscGithubInverted } from "react-icons/vsc";
import { HiDocumentDownload  } from "react-icons/hi"; // Importing a mail icon for the new button

import { HomeContainer } from "../components/LayoutComponents";

const Intro = () => {
  return (
    <HomeContainer marginTop={{ base: 0, md: 5 }}>
      <SlideFade
        in={true}
        offsetY="30px"
        transition={{ enter: { duration: 0.3, delay: 0.2 } }}
      >
        <Heading size="lg" marginY={5}>
          Hey there! My name is
        </Heading>
        <Heading
          marginY={8}
          fontSize={{ base: "6xl", md: "9xl" }}
          letterSpacing="-2px"
          bgGradient="linear(to-r, var(--theme-green), var(--theme-aqua))"
          bgClip="text"
          fontWeight="extrabold"
        >
          Wesley Kamau
        </Heading>
        <Heading size="xl" marginBottom={16}>
          I develop web applications from end-to-end!
          <br />I also love working with AI.
        </Heading>

        <Flex
          marginX="auto"
          marginY="2rem"
          paddingX={{ base: 8, md: 5 }}
          direction={{ base: "column", md: "row" }} // Stack vertically on small screens
          align="center" // Center items horizontally
          justify={{ base: "center", md: "space-evenly" }} // Center items on small screens, space evenly on larger screens
          gap={{ base: 4, md: 6 }} // Add uniform space between buttons
        >
          <Link href="https://github.com/WesleyKamau" target="_blank" rel="noreferrer">
            <Button 
              aria-label="My GitHub" 
              p={10} // Apply padding to the button
              fontSize="2xl"
              marginBottom={{ base: 4, md: 0 }} // Bottom margin for stacked buttons
            >
              <Icon as={VscGithubInverted} marginRight={4} /> My GitHub
            </Button>
          </Link>

          <Link href="/WesleyKamau.pdf" download>
            <Button 
              aria-label="My Resume" 
              p={10} // Apply padding to the button
              fontSize="2xl"
            >
              <Icon as={HiDocumentDownload} marginRight={4} /> My Resume
            </Button>
          </Link>
        </Flex>
        
      </SlideFade>
    </HomeContainer>
  );
};

export default Intro;
