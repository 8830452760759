import { IoLogoReact, IoLogoPython, IoLogoFigma, IoLogoNodejs } from "react-icons/io5";
import {
  SiFlask,
  SiMongodb,
  SiUnrealengine,
  SiGit,
  SiFastapi,
  SiTypescript,
  SiNextdotjs,
  SiGatsby,
  SiJavascript,
  SiJava,
  SiCplusplus,
  SiHtml5,
  SiCss3,
  SiLabview,
  SiLatex,
  SiEclipseide,
  SiVisualstudio,
  SiVisualstudiocode,
  SiFigma,
  SiSpringboot,
  SiTailwindcss,
  SiWordpress,
  SiJunit5,
  SiGithub,
  SiHeroku,
  SiJson,
  SiLinux,
  SiGooglecolab,
  SiTensorflow,
  SiSelenium,
  SiNumpy,
  SiBootstrap,
  SiAdobepremierepro,
  SiAdobephotoshop
} from "react-icons/si";

import { Skill } from "../types/HomeSections";

export const skills: { [skillType: string]: Skill[] } = {
  frontend: [
    {
      icon: SiJavascript,
      name: "JavaScript",
      color: "#e8d44d"
    },
    {
      icon: IoLogoReact,
      name: "React",
      color: "#61DAFB"
    },
    {
      icon: SiTypescript,
      name: "TypeScript",
      color: "#2f74c0"
    },
    // {
    //   icon: SiNextdotjs,
    //   name: "Next.js",
    //   color: "#FFFFFF"
    // },

    {
      icon: SiHtml5,
      name: "HTML",
      color: "#dd4b25"
    },
    {
      icon: SiCss3,
      name: "CSS",
      color: "#3595cf"
    },
    {
      icon: SiTailwindcss,
      name: "Tailwind CSS",
      color: "#38bdf8"
    },
    {
      icon: SiWordpress,
      name: "Wordpress",
      color: "#207196"
    },
    {
      icon: SiGatsby,
      name: "Gatsby",
      color: "#633194"
    },
    {
      icon: IoLogoNodejs,
      name: "Node.js",
      color: "#509941"
    }
  ],

  backend: [
    {
      icon: SiJava,
      name: "Java",
      color: "#05998b"
    },
    {
      icon: IoLogoPython,
      name: "Python",
      color: "#4584b6"
    },
    {
      icon: SiCplusplus,
      name: "C++",
      color: "#6195cb"
    },
    {
      icon: SiFlask,
      name: "Flask",
      color: "#EEEEEE"
    },

    {
      icon: SiMongodb,
      name: "MongoDB",
      color: "#4EA94B"
    },
    {
      icon: SiLabview,
      name: "LabVIEW",
      color: "#ffde00"
    },
    {
      icon: SiSpringboot,
      name: "Spring Boot",
      color: "#6aad3d"
    },
    {
      icon: SiJunit5,
      name: "JUnit",
      color: "#d54e48"
    },
    {
      icon: SiHeroku,
      name: "Heroku",
      color: "#645fa1"
    },
    {
      icon: SiTensorflow,
      name: "TensorFlow",
      color: "#e68a23"
    },
    {
      icon: SiSelenium,
      name: "Selenium",
      color: "#00ae00"
    },
    {
      icon: SiNumpy,
      name: "NumPy",
      color: "#4b73c9"
    }
  ],

  others: [
    {
      icon: SiGithub,
      name: "GitHub",
      color: "#171515"
    },
    {
      icon: SiGit,
      name: "Git",
      color: "#e84d31"
    },
    {
      icon: SiEclipseide,
      name: "Eclipse",
      color: "#ef8f1d"
    },
    {
      icon: SiVisualstudio,
      name: "Visual Studio",
      color: "#aa75e9"
    },
    {
      icon: SiVisualstudiocode,
      name: "Visual Studio Code",
      color: "#0074c8"
    },
    {
      icon: IoLogoFigma,
      name: "Figma",
      color: "#2c2c33"
    },

    {
      icon: SiJson,
      name: "JSON",
      color: "#1f1f1f"
    },
    {
      icon: SiLinux,
      name: "Linux",
      color: "#f5f5f3"
    },
    {
      icon: SiGooglecolab,
      name: "Google Colab",
      color: "#e56c07"
    },
    {
      icon: SiLatex,
      name: "LaTeX",
      color: "#008080"
    },
    // {
    //   icon: SiWordpress,
    //   name: "WordPress",
    //   color: "#4EA94B"
    // },
    {
      icon: SiAdobepremierepro,
      name: "Adobe Premiere Pro",
      color: "#9a9aff"
    },
    {
      icon: SiAdobephotoshop,
      name: "Adobe Photoshop",
      color: "#2daaff"
    },
    // },
    // {
    //   icon: SiUnrealengine,
    //   name: "Unreal Engine 4",
    //   color: "#D2D7D3"
    // }
  ]
};
