import { Project } from "../types/HomeSections";

export const projectList: Project[] = [
  {
    name: "MemoryMap",
    description:
      "A full-stack React app that allows users to upload images and create custom games with location metadata. Includes multi-step forms for game creation, a Spring Boot backend with MongoDB for storing data, and a RESTful API for frontend-backend communication.",
    tags: ["JavaScript", "React", "Node.js", "Tailwind", "Java", "Spring Boot", "MongoDB"],
    link: "https://wesleykamau.com/memorymap",  // Replace with the actual link
    github: "https://github.com/WesleyKamau/memorymap",  // Replace with the actual link
    source: "previews/memorymap.png",  // Replace with the actual source
    mobileSite: true
  },
  {
    name: "Matrix (Linear Algebra)",
    description:
      "A Java application for performing various matrix operations including addition, subtraction, multiplication, inversion, and determinant calculation. Designed with user-friendly interfaces for matrix input and result display.",
    tags: ["Java"],
    // link: "https://example.com/matrix",  // Replace with the actual link
    github: "https://github.com/WesleyKamau/matrix",  // Replace with the actual link
    source: "previews/matrix.png"  // Replace with the actual source
  },
  {
    name: "NowPlaying",
    description:
      "A full-stack app that uses song recognition to visually display the record playing on my record player. Integrates the Shazam API to fetch and display the latest song information, including album details and cover art.",
    tags: ["JavaScript", "React", "Python", "Flask", "Shazam API"],
    // link: "https://wesley.com/nowplaying",  // Replace with the actual link
    github: "https://github.com/WesleyKamau/nowplaying",  // Replace with the actual link
    source: "previews/nowplaying.png"  // Replace with the actual source
  },
  {
    name: "StyleSwap",
    description:
      "A project that implements neural style transfer using TensorFlow and Keras. Utilizes Spotipy for retrieving album cover images and PIL for image manipulation and output.",
    tags: ["Python", "TensorFlow", "Google Colab"],
    // link: "https://example.com/styleswap",  // Replace with the actual link
    github: "https://github.com/WesleyKamau/HackAI-2024",  // Replace with the actual link
    source: "previews/styleswap.png"  // Replace with the actual source
  },
  {
    name: "AutoArchiver",
    description:
      "A project for automatically archiving youtube channels with selenium to handle the video uploading process. Handles retrieving the thumbnail, title, and description of the video.",
    tags: ["Python", "Selenium", "PIL", "Youtube API"],
    // link: "https://example.com/styleswap",  // Replace with the actual link
    github: "https://github.com/WesleyKamau/AutoArchiver",  // Replace with the actual link
    source: "previews/autoarchiver.png"  // Replace with the actual source
  },
  {
    name: "Fortnite Stats",
    description:
      "A project that retrieves the fortnite stats of a player using the fortnite API. The project is built using HTML, CSS, and JavaScript.",
    tags: ["JavaScript", "HTML", "CSS", "Fortnite API"],
    link: "https://wesleykamau.com/fortnite",  // Replace with the actual link
    github: "https://github.com/WesleyKamau/fortnite",  // Replace with the actual link
    source: "previews/fortnite.png",  // Replace with the actual source
    mobileSite: true
  },
  {
    name: "WTFont",
    description:
      "A Javascript project that originated from an idea, \"What if every character I typed was in a different font?\". The project is built using HTML, CSS, and JavaScript.",
    tags: ["JavaScript", "HTML", "CSS"],
    link: "https://wesleykamau.com/WTFont",  // Replace with the actual link
    github: "https://github.com/WesleyKamau/WTFont",  // Replace with the actual link
    source: "previews/wtfont.png",  // Replace with the actual source
    mobileSite: false
  }
];
