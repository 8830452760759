import React from "react";
import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { Image as CNImage, Transformation } from "cloudinary-react";
import Image from "react-simple-image";

import {
  AnimatedContainer,
  HomeContainer,
  SectionHeading
} from "../components/LayoutComponents";
import { AboutDescription } from "../content/About";

const About = () => {
  return (
    <HomeContainer id="about" justifyContent="center">
      <SectionHeading>About me</SectionHeading>
      <AnimatedContainer justifySelf="center">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex direction="column" justifyContent="space-evenly">
            <Text fontSize="xl">{AboutDescription[0]}</Text>
            <br></br>
            <Text fontSize="xl">{AboutDescription[1]}</Text>
          </Flex>

          <Flex
            marginX="auto"
            width="300px"
            border="4px solid"
            borderColor="var(--theme-aqua)"
          >
            <img
              src="about_photo.png"
              alt="My photo"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Flex>
        </SimpleGrid>
      </AnimatedContainer>
    </HomeContainer>
  );
};


export default About;
